import { createSelector } from "reselect";

import { RootState } from "./store";
import { assetAdapter, AssetState } from "./slices/assetSlice";
import { organisationAdapter, OrganisationState } from "./slices/organisationSlice";
import { FormState } from "./slices/formSlice";


const assetStateSelector = (state: RootState): AssetState => state.assets;

const organisationStateSelector = (state: RootState): OrganisationState => state.organisations;

export const assetListSelector = assetAdapter.getSelectors(assetStateSelector)
  .selectAll;

export const organisationListSelector = organisationAdapter.getSelectors(organisationStateSelector)
  .selectAll;

export const currentAssetSelector = createSelector(
  assetStateSelector,
  (assetState) =>
    assetState.selectedAsset
      ? assetState.entities[assetState.selectedAsset]
      : undefined
);

export const loadingAssetSelector = createSelector(
  assetStateSelector,
  (assetState) => assetState.loadingAssets
);

export const loadingOrganisationSelector = createSelector(
  organisationStateSelector,
  (orgState) => orgState.loadingOrganisations
);

export const formStateSelector = (state: RootState): FormState => state.form;

export const isEditModeSelector = createSelector(
  formStateSelector,
  (formState) => formState.isEditMode
);

export const isNewItemSelector = createSelector(
  formStateSelector,
  (formState) => formState.isNewItem
);

export const userCanEditSelector = createSelector(
  formStateSelector,
  (formState) => formState.userCanEdit
);
