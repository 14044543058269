import { Organisation } from "../redux/slices/organisationSlice";

export const defaultOrganisations: Organisation[] = [
    {
        id: "f6256456-a8f5-4146-9102-983f7ea0a713",
        name: "smartest"
    },
    {
        id: "c291848d-4491-4253-8eda-de9ed05daf86",
        name: "demo",
    },
    {
        id: "d7abdce0-51be-48f9-99a0-020f8b0a0287",
        name: "test",
    },
    {
        id: "a62b016b-d66a-405e-9275-d12ed190eded",
        name: "origami"
    },
    {
        id: "48bd8ba5-10cb-4bdf-b3cf-8bc3380b7d88",
        name: "eneco"
    }
]
