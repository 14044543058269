import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { assetSlice, getAssetState } from "./slices/assetSlice";
import { rootSagas } from "./sagas";
import { IAssetsApiClient } from "../AssetsApiClient";
import { formSlice, getFormState } from "./slices/formSlice";
import { getOrganisationState, organisationSlice } from "./slices/organisationSlice";

export const reducer = combineReducers({
  assets: assetSlice.reducer,
  form: formSlice.reducer,
  organisations: organisationSlice.reducer
});

export type RootState = ReturnType<typeof reducer>;

export const setupStore = (apiClient: IAssetsApiClient) => {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      apiClient,
    },
  });

  const store = configureStore({
    preloadedState: {
      assets: getAssetState([], undefined),
      form: getFormState(false, false, false),
      organisations: getOrganisationState([])
    },
    reducer,
    middleware: [...getDefaultMiddleware(), sagaMiddleware],
  });

  sagaMiddleware.run(rootSagas);
  
  store.dispatch(assetSlice.actions.getAssetList());
  store.dispatch(organisationSlice.actions.getOrganisationList());
  store.dispatch(formSlice.actions.checkUserAccess());
  return store;
};
