import { v4 as uuid } from 'uuid';
import low, { LowdbSync } from 'lowdb';
import Memory from 'lowdb/adapters/Memory';
import LocalStorage from 'lowdb/adapters/LocalStorage';

import { defaultAssets } from './assets';
import { Asset } from '../redux/slices/assetSlice';
import { defaultOrganisations } from './organisations';

const ASSETS = 'ASSETS';
const ORGANISATIONS = 'ORGANISATIONS';

let _nodejs =
  typeof process !== 'undefined' && process.versions && process.versions.node;

const adapter = _nodejs ? new Memory('tmp') : new LocalStorage('dashboard');

const db: LowdbSync<{ [ASSETS]: {id: string, name: string, owner?: string}[], [ORGANISATIONS]: { id: string, name: string }[];}> = low(adapter);

db.defaults({ [ASSETS]: defaultAssets, [ORGANISATIONS]: defaultOrganisations }).write();

class FakeDb {

    reset = () => db.setState({ [ASSETS]: defaultAssets, [ORGANISATIONS]: defaultOrganisations } );

    getAssets = async() => await db.get(ASSETS).value();

    getOrganisations = async() => await db.get(ORGANISATIONS).value();

    getAsset = async(id: string) => await db.get(ASSETS).find({id: id}).value();

    getOrganisation = async(id: string) => await db.get(ORGANISATIONS).find({id: id}).value();

    addAsset = async(name: string, owner: string) => {
        const newId = uuid();
        const asset = { 
            id: newId,
            name,
            type: "SITE",
            owner,
            lastUpdated: 0
        }
        await db.get(ASSETS).push(asset).write();
        return asset
    }

    updateAsset = async(assetId: string, newName: string, newOwner: string): Promise<Asset> => {
        await db.get(ASSETS)
            .chain()
            .find({ id: assetId })
            .assign({
                name: newName,
                owner: newOwner
            }).value();
            db.write();
        return {
            id: assetId,
            label: newName,
            owner: newOwner,
            lastUpdated: 0
        };
    }

    deleteAsset = async(assetId: string): Promise<void> => {
        let assets = await db.get(ASSETS).value();
        const toRemove = assets.find(a => a.id === assetId);
        if (toRemove) assets.splice(assets.indexOf(toRemove));

        db.set(ASSETS, assets).value();
        db.write();
    }
}

export default FakeDb;