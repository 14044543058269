import React from "react";
import ReactDOM from "react-dom";
import { StyleSheetManager } from "styled-components";
import retargetEvents from "react-shadow-dom-retarget-events";

import { CustomWidgetBase } from "./CustomWidgetBase";
import { AppProps } from "./App";
import AppWrapper from "./AppWrapper";
import ExternalActionsProvider from "./ExternalEventListenerProvider";
import { AssetsApiClient } from "./AssetsApiClient";

import createMockServer from "./mock/server";

declare global {
  interface ImportMeta {
    env?: { [key: string]: string };
  }
}

// stores and other per instance object should be created in the mount() method.
const getConfig = (baseUrl: string) => {
  return fetch(baseUrl + "config.json").then((r) => r.json());
};

class CustomComponent extends CustomWidgetBase<AppProps> {
  private reactRoot?: HTMLDivElement;
  private styleInsertionPoint: HTMLElement;
  private eventCleanup: () => void = () => {};
  private apiClientPromise?: Promise<AssetsApiClient>;
  private configPromise: Promise<any>;

  constructor() {
    super([
      // { iconName: "favorite", key: "favorite", title: "Favorite" },
      // { iconName: "face", key: "face", title: "Face" },
    ]);

    this.reactRoot = document.createElement("div");
    this.reactRoot.setAttribute("style", "width: 100%; height: 100%");
    this.styleInsertionPoint = document.createElement("noscript");
    this.root.appendChild(this.styleInsertionPoint);
    this.root.appendChild(this.reactRoot);
    this.configPromise = getConfig(this.baseUrl);
    this.configPromise.then((config) => {
      if (import.meta.env?.VITE_REACT_APP_MOCKAPI === "true") {
        createMockServer(config.apiUrl);
      }
    });
  }

  mount() {
    this.apiClientPromise = this.configPromise.then((config) =>
    AssetsApiClient.build(config.apiUrl)
    );
    ReactDOM.render(
      <React.StrictMode>
        <StyleSheetManager target={this.styleInsertionPoint}>
          <ExternalActionsProvider value={this.eventListenerRegistry}>
            <AppWrapper
              apiClientPromise={this.apiClientPromise}
              {...this.props}
            />
          </ExternalActionsProvider>
        </StyleSheetManager>
      </React.StrictMode>,
      this.reactRoot!
    );
    // @ts-ignore
    this.eventCleanup = retargetEvents(this.root);
  }

  unmount() {
    if (this.reactRoot) ReactDOM.unmountComponentAtNode(this.reactRoot);
    this.eventCleanup();
    this.apiClientPromise?.then((apiClient) => apiClient.cleanup());
  }
}

customElements.define("widget-assets", CustomComponent);
