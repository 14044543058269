import { AxiosRequestConfig, AxiosInstance } from "axios";

import {
  ApiClient,
  IApiClient,
} from "@OrigamiEnergyLtd/renewables-plus-api-client";
import { Asset } from "./redux/slices/assetSlice";
import { Organisation } from "./redux/slices/organisationSlice";

export interface IAssetsApiClient extends IApiClient {
  retrieveAssets(): Promise<Asset[]>;
  retrieveOrganisations(): Promise<Organisation[]>;
  addAsset(asset: Asset): Promise<string>;
  updateAsset(asset: Asset): Promise<void>;
  deleteAsset(assetId: string): Promise<void>;
  getTokenAttribute(attribute: string): unknown | undefined;
  isUserAdmin(): boolean;
  logout(): void;
}

export class AssetsApiClient implements IApiClient {
  private axiosInstance: AxiosInstance;
  private constructor(private apiClient: ApiClient) {
    this.axiosInstance = apiClient.axiosInstance;
  }

  public static async build(baseApi: string) {
    const apiClient = await ApiClient.build(baseApi);
    return new AssetsApiClient(apiClient);
  }

  public cleanup() {
    this.apiClient.cleanup();
  }

  public logout() {
    return this.apiClient.logout();
  }

  public getTokenAttribute(attribute: string) {
    return this.apiClient.getTokenAttribute(attribute);
  }

  public isUserAdmin() {
    return this.apiClient.hasRole("origami_admin");
  }

  public async retrieveAssets() {
    const url = "/v1/assets";
    const config: AxiosRequestConfig = {
      params: {
        page: 0,
        size: 350,
        sort: "name,asc",
      },
    };
    const response = await this.axiosInstance.get(url, config);
    const assets: Asset[] = response.data.content.map((rawAsset: any) => {
      return {
        id: rawAsset.id,
        label: rawAsset.resource.name,
        owner: rawAsset.resource.owner,
        lastUpdated: 0,
      };
    });
    return assets;
  }

  public async retrieveOrganisations() {
    const url = "/v1/organisations";
    const config: AxiosRequestConfig = {
      params: {
        page: 0,
        sort: "name,asc",
      },
    };
    const response = await this.axiosInstance.get(url, config);
    const orgs: Organisation[] = response.data.content.map((rawOrg: any) => {
      return {
        id: rawOrg.id,
        name: rawOrg.resource.name,
      };
    });
    return orgs;
  }

  public async addAsset(asset: Asset) {
    const url = "/v1/assets";
    const body = JSON.stringify({
      name: asset.label,
      type: "SITE",
      owner: asset.owner,
    });
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "Application/json",
      },
    };

    const response = await this.axiosInstance.post(url, body, config);
    const newAssetLocation: string = response.headers.location;

    const newId = newAssetLocation.substr(
      newAssetLocation.indexOf("assets/") + "assets/".length
    );
    return newId;
  }

  public async updateAsset(asset: Asset) {
    const url = `/v1/assets/${asset.id}`;
    const body = [
      {
        path: "/name",
        value: asset.label,
        op: "replace",
      },
      {
        path: "/owner",
        value: asset.owner,
        op: "replace",
      },
    ];
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };
    await this.axiosInstance.patch(url, body, config);
  }

  public async deleteAsset(assetId: string) {
    const url = `v1/assets/${assetId}`;

    await this.axiosInstance.delete(url);
  }
}
