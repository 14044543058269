import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";

export type Asset = {
    id: string;
    label: string;
    owner: string; // The id of the owner organisation
    lastUpdated: number;
};

export type AssetState = EntityState<Asset>  & {
    selectedAsset?: string,
    loadingAssets: boolean
};

export const assetAdapter = createEntityAdapter<Asset>(
    {
        selectId: asset => asset.id,
        sortComparer: (a,b) => a.label.localeCompare(b.label)
    }
)

export const getAssetState = (assetList: Asset[], selectedAsset?: string) => ({
    ids: assetList.map(asset => asset.id),
    entities: assetList.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {}),
    selectedAsset: selectedAsset,
    loadingAssets: false
})

export type UpdateAssetPayload = {
    assets: Asset[],
    clearSelected: boolean,
    clearEditMode: boolean
}

export const assetSlice = createSlice({
    name: 'assets',
    initialState: getAssetState([]),
    reducers: {
        updateAssets: (state, action: PayloadAction<UpdateAssetPayload>) => {
            state.loadingAssets = false;

            // Can't just remove and re-add assets as that would reset edits in progress when we refresh the list
            // So instead remove any from state that aren't in the response. Update the others, but not the one currently being edited
            const ids = action.payload.assets.map(a => a.id);
            const removedAssets = state.ids.filter(id => !ids.includes(id))
            assetAdapter.removeMany(state, removedAssets);

            const toUpdate = action.payload.assets;
            
            const selectedAssetIndex = state.selectedAsset ? toUpdate.findIndex(a => a.id === state.selectedAsset) : -1;
            // only hold on updating the selected asset if we're not clearing edit mode
            if (!action.payload.clearEditMode && selectedAssetIndex > -1) {
                toUpdate.splice(selectedAssetIndex, 1);
            }
            assetAdapter.upsertMany(state, toUpdate);       

            if (action.payload.clearSelected) {
                delete state.selectedAsset;
                if(action.payload.assets.length > 0) {
                    state.selectedAsset = action.payload.assets[0].id;
                }
            }
        },
        getAssetList: (state) => { state.loadingAssets = true; }, 
        selectAsset: (state, action: PayloadAction<string>) => { state.selectedAsset = action.payload },
        clearSelectedAsset: (state) => { delete state.selectedAsset },
        deleteAsset: (state, action: PayloadAction<string>) => { },
        refresh: (state) => { }
    }
})
