import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import { Asset, assetSlice } from "./assetSlice";

export type FormState = {
    isEditMode: boolean,
    isNewItem: boolean,
    userCanEdit: boolean
};

export type SetEditModePayload = {
    isEditMode: boolean,
    isNewMode: boolean
}

export const getFormState = (isEditMode: boolean, isNewItem: boolean, userCanEdit: boolean): FormState => ({
    isEditMode,
    isNewItem,
    userCanEdit
})

export const formSlice = createSlice({
    name: 'form',
    initialState: getFormState(false, false, false),
    reducers: {
        checkUserAccess: () => { },
        setUserCanEdit: (state, action: PayloadAction<boolean>) => {
            state.userCanEdit = action.payload;
        },
        editAsset: (state) => {
            if(state.userCanEdit) {
                state.isEditMode = true;
            }
        },
        addNewAsset: (state) => {
            if (state.userCanEdit) {
                state.isEditMode = true;
                state.isNewItem = true;
            }
        },
        saveAsset: (state, action: PayloadAction<Asset>) => { },
        cancelEdit: (state) => {
            state.isEditMode = false;
            state.isNewItem = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(assetSlice.actions.updateAssets, (state, action) => {
            if (action.payload.clearEditMode) {
                state.isEditMode = false;
                state.isNewItem = false;
            }
        });
    }
})
