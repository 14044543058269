import {createEntityAdapter, createSlice, EntityState, PayloadAction} from "@reduxjs/toolkit";

export type Organisation = {
    id: string;
    name: string;
};

export type OrganisationState = EntityState<Organisation> & {
    loadingOrganisations: boolean;
};

export const organisationAdapter = createEntityAdapter<Organisation>(
    {
        selectId: org => org.id,
        sortComparer: (a,b) => a.name.localeCompare(b.name)
    }
);

export const getOrganisationState = (orgList: Organisation[]) => ({
    ids: orgList.map(org => org.id),
    entities: orgList.reduce((acc, curr) => ({...acc, [curr.id]: curr}), {}),
    loadingOrganisations: false
});

export const organisationSlice = createSlice({
    name: 'organisations',
    initialState: getOrganisationState([]),
    reducers: {
        getOrganisationList: (state) => { state.loadingOrganisations = true; }, 
        refresh: (state) => { },
        updateOrganisations: (state, action: PayloadAction<Organisation[]>) => {
            state.loadingOrganisations = false;

            organisationAdapter.removeAll(state);
            organisationAdapter.addMany(state, action.payload);
        }
    }
});