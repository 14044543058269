type Asset = {
    id: string;
    name: string;
    owner?: string
}

export const defaultAssets: Asset[] = [
    {
        id: "2f0a92a4-38f1-446a-8be6-8f97425728d4",
        name: "carno"
    },
    {
        id: "f4ad1ce7-0607-4666-8474-30201c547d6d",
        name: "ne8o1",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "2793b311-9a66-4b87-8d74-31726788e4af",
        name: "q0fnyu",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "6a1c2d17-11c5-4b56-8d89-b90327ecfe93",
        name: "r8g0el",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "8598d6ef-50ac-4c79-afd0-53d9a5e1aead",
        name: "i08tvw",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "79e07840-342b-4813-991e-5439f5ff9821",
        name: "kn977r",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "46675941-10d1-454a-89ec-39d4a8e995d2",
        name: "e24htj",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "fc68574c-aefb-42ea-a8f2-dea479387f87",
        name: "9pspin",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "2bfce444-2fbc-4f56-9ec3-25260598b605",
        name: "4h576n",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "1309e328-9858-4dfe-84c4-2ca1cd7c8f1c",
        name: "hic59f1599124206814",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713",
        
    },
    {
        id: "eb042376-dbd0-4fcf-b6fe-43271cab61ab",
        name: "gehfsc1599125385871",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "ae3a222d-aca2-45e0-bb60-8b9a643f6b7b",
        name: "xve7c1599125489179",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "629848d6-94ba-42b2-81d7-b7e2edf9727a",
        name: "uvc0k1599125511401",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "ac1b27e8-90aa-45d7-91ae-19c695d3cbc2",
        name: "ei7qd5e1599125552979",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "77fd9f3d-075a-49e0-81eb-039e2660a18c",
        name: "djnnvv1599125652885",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    },
    {
        id: "8f6860dc-3d23-465a-89f3-f8df4168cff0",
        name: "lmm3rc1599125716390",
        owner: "f6256456-a8f5-4146-9102-983f7ea0a713"
    }
];
