import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AssetForm from "./components/AssetForm";
import AssetList from "./components/AssetList";

import {
  currentAssetSelector,
  isEditModeSelector,
  loadingAssetSelector,
  userCanEditSelector,
  assetListSelector,
  loadingOrganisationSelector
} from "./redux/selectors";
import { formSlice } from "./redux/slices/formSlice";
import { Asset, assetSlice } from './redux/slices/assetSlice';

export type AppProps = {
  widgetId: string;
};

const App: FC<AppProps> = ({ widgetId }) => {
  const assets = useSelector(assetListSelector)
  const assetsLoading = useSelector(loadingAssetSelector);
  const organisationsLoading = useSelector(loadingOrganisationSelector);
  const isEditMode = useSelector(isEditModeSelector);
  const selectedAsset = useSelector(currentAssetSelector);
  const userCanEdit = useSelector(userCanEditSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(assetSlice.actions.refresh());
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch])

  const editItem = () => {
    if(!isEditMode) dispatch(formSlice.actions.editAsset());
  }

  const deleteItem = () => {
    if (!isEditMode && selectedAsset)
      dispatch(assetSlice.actions.deleteAsset(selectedAsset.id))
  }
  
  const handleSubmit = (asset: Asset) => {
    dispatch(formSlice.actions.saveAsset(asset))
  }

  const handleCancel = () => {
    dispatch(formSlice.actions.cancelEdit())
    if(!selectedAsset && assets[0]) {
      dispatch(assetSlice.actions.selectAsset(assets[0].id));
    }
  }

  const addNewAsset = () => {
    if(!isEditMode) {
      dispatch(assetSlice.actions.clearSelectedAsset());
      dispatch(formSlice.actions.addNewAsset());
    }
  }

  if (assetsLoading || organisationsLoading) {
    return <div data-testid="loading">Loading...</div>;
  }
  return (
    <>
      <div className="app" 
        data-testid={widgetId}
        style={{
          width: "100%",
          height: "100%",
          display: "flex"
        }}
      >
        <div
          data-testid="assetListContainer"
          style={{
            height: "100%",
            width: "33%",
            overflowY: "auto",
            overflowX: "hidden"
          }}
        >
          <AssetList onClickAdd={addNewAsset}/>
        </div>
        <div
          data-testid="assetFormContainer"
          style={{
            height: "100%",
            width: "66%",
            overflowY: "auto",
            overflowX: "hidden",
            paddingTop: "2px",
            paddingLeft: "2px"
          }}
        >
          <AssetForm 
            selectedAsset={selectedAsset}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            onEditItemClick={editItem}
            onDeleteItemClick={deleteItem}
            userCanEdit={userCanEdit}
            isEditMode={isEditMode}
          />
        </div>
      </div>
    </>
  );
};

export default App;
