import React, { FC, useState, useEffect } from "react";
import { PencilSquare } from "@styled-icons/bootstrap/PencilSquare"

import { Asset } from "../../redux/slices/assetSlice";
import { organisationListSelector } from "../../redux/selectors";
import LabelledInput from "../LabelledInput";
import { emptyAsset, emptyAssetOwner } from "../../constants";
import { useSelector } from "react-redux";

export type AssetFormProps = {
    selectedAsset: Asset | undefined;
    handleSubmit: (asset: Asset) => void;
    handleCancel: () => void;
    onEditItemClick: () => void; 
    onDeleteItemClick: () => void;
    userCanEdit: boolean;
    isEditMode: boolean;
}

// Used to show / hide the delete button so it can't accidentally appear
// The button is just there for now to enable cleanup of all the junk assets created during dev
// Permanently hidden for now, as we don't want users seeing it.
const showDeleteButton = false;

const AssetForm: FC<AssetFormProps> = ({
    selectedAsset,
    handleSubmit,
    handleCancel,
    onEditItemClick,
    onDeleteItemClick,
    userCanEdit,
    isEditMode,
}) => {

    const ownersList = useSelector(organisationListSelector)

    const getAssetForForm = (asset: Asset | undefined) => {
        const newAsset = asset ? {...asset} : {...emptyAsset}
        if (!newAsset.owner) {
            newAsset.owner = emptyAssetOwner.key
        }
        return newAsset;
    }

    const [formAsset, setFormAsset] = useState<Asset>(getAssetForForm(selectedAsset))

    useEffect(() => {
        setFormAsset(getAssetForForm(selectedAsset));
    }, [selectedAsset]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const validationErrors = validateInputs();
        e.preventDefault();
        e.stopPropagation(); // Need to stop event propagating to parent components here else form onSubmit gets called twice
        if (validationErrors.length === 0) {
            handleSubmit(formAsset);
        }
        else {
            // TODO: Nicer way of handling validation errors needed
            alert(validationErrors);
        }
    }

    const validateInputs = () => {
        const errors: string[] = [];
        // TODO: More validation needed here
        if (!formAsset.owner) {
          errors.push('no asset owner provided');
        }
        if (ownersList.map(o => o.id).indexOf(formAsset.owner) === -1 || formAsset.owner === 'none') {
          errors.push("asset owner is not valid");
        }
        return errors;
    }

    const handleOwnerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newOwner = ownersList.find(o => o.id === e.target.value)
        const newAsset = {...formAsset, owner: newOwner!.id}
        setFormAsset(newAsset);
    }

    const selectOwnerElement = () => {
        const testId = `assetOwner${isEditMode ? 'Select' : 'Input'}`;
        if (isEditMode) return (
        <select
            data-testid={testId}
            style={{ width: "8rem" }}
            className={isEditMode ? 'editable-input' : 'disabled'}
            required
            onChange={handleOwnerChange}
            disabled={!isEditMode}
            value={
                ownersList.find(o => o.id === formAsset.owner)?.id ??
                emptyAssetOwner.value
            }
        >
            <option
                key={emptyAssetOwner.key}
                value={emptyAssetOwner.value}
            >
                {emptyAssetOwner.value}
            </option>
            {ownersList.map(o => (
                <option 
                    key={o.id}
                    value={o.id}
                >
                    {o.name}
                </option>
            ))}
        </select>
        )
        else return (
            <input type="text"
                data-testid={testId}
                disabled
                required
                value={
                    ownersList.find(o => o.id === formAsset.owner)?.name ??
                    emptyAssetOwner.value
                }
            />
        )
    }

    const onCancel = () => {
        setFormAsset(selectedAsset ? {...selectedAsset} : {...emptyAsset});
        handleCancel();
    }

    return(
        <div>
            <div data-testid="assetFormHeader">
                {userCanEdit && selectedAsset ? (
                    <>
                        <button 
                            data-testid="editAssetButton"
                            style={{ border: "none", backgroundColor: "transparent" }}
                            disabled={isEditMode}
                            onClick={onEditItemClick}
                        >
                            <div style={{ padding: "0.25rem", width: "1.5rem", height: "1.5rem", textAlign: "center" }}>
                                <PencilSquare color={isEditMode ? "gray" : "white"}/>
                            </div>
                        </button>
                        { /* Temporary Delete button so we can clean up assets added in dev */
                          showDeleteButton ? (
                            <button
                            style={{ color: "white", border: "none", backgroundColor: "transparent" }}
                            data-testid="deleteAssetButton"
                            disabled={isEditMode}
                            onClick={onDeleteItemClick}
                        >
                            Delete
                        </button>
                        ) : null}
                    </>
                ) : null}
            </div>
            <div>
                <form onSubmit={onSubmit} data-testid="assetForm">
                    <div>
                        <LabelledInput 
                            label="Asset id" input={
                                <input
                                    data-testid="assetIdInput"
                                    type="text" 
                                    disabled value={formAsset.id} required
                                />
                            }
                        />
                        <LabelledInput
                            label="Name"
                            input={
                                <input
                                    data-testid="assetNameInput"
                                    type="text"
                                    required
                                    disabled={!isEditMode}
                                    value={formAsset.label}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        e.preventDefault();
                                        setFormAsset({...formAsset, label: e.target.value})
                                    }}
                                />
                            }
                        />
                        <LabelledInput
                            label="Owner"
                            input={selectOwnerElement()}
                        />
                    </div>
                    {userCanEdit && isEditMode ? (
                        <div data-testid="formFooter">
                            <button 
                                data-testid="saveEdit"
                                style={{ padding: "0.25rem", margin: "0.5rem" }}
                                type="submit"
                                disabled={!isEditMode}
                            >
                                Save
                            </button>
                            <button
                                data-testid="cancelEdit"
                                style={{ padding: "0.25rem", margin: "0.5rem" }}
                                onClick={onCancel}
                                disabled={!isEditMode}
                            >
                                Cancel
                            </button>
                        </div>) : 
                        null
                    }
                </form>
            </div>
        </div>
    )
}

export default AssetForm