import React, { FC } from "react";
import { Asset, assetSlice } from "../../redux/slices/assetSlice";
import { useDispatch, useSelector } from "react-redux";
import { assetListSelector, currentAssetSelector, isEditModeSelector, userCanEditSelector } from "../../redux/selectors";

import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline"

export type AssetListProps = {
  onClickAdd: () => void
}

const AssetList: FC<AssetListProps> = ({ onClickAdd }) => {
  const assets = useSelector(assetListSelector);
  const selectedAsset = useSelector(currentAssetSelector);
  const formIsEditMode = useSelector(isEditModeSelector);
  const userCanEdit = useSelector(userCanEditSelector);

  const dispatch = useDispatch();

  const classIsAssetSelected = (currentAsset: Asset) =>
    currentAsset.id === selectedAsset?.id ? "selected" : "";

  const handleSelect = (assetId: string) => {
    if (!formIsEditMode) dispatch(assetSlice.actions.selectAsset(assetId))
  }

  const assetList = assets.map((asset) => (
    <li
      style={{
        width: "100%",
        height: "2.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "1px 0px",
        padding: "0px 1rem",
        marginBottom: "0.25rem"
      }}
      className={`paper ${classIsAssetSelected(asset)}`}
      key={asset.id}
      onClick={() => handleSelect(asset.id)}
      data-testid="assetListItem"
    >
      <div>{asset.label}</div>
    </li>
  ));

  return (
    <div>
      <div
        data-testid="assetListHeader"
      >
        <div
          style={{
            float: "left",
            padding: "0.5rem",
            textAlign: "center"
          }}
        >
          <strong>Assets</strong>
        </div>
        {userCanEdit && !formIsEditMode ? (
          <div
            style={{
              float: "right"
            }}
          >
            <button 
              style={{
                border: "none",
                backgroundColor: "transparent"
              }}
              onClick={onClickAdd}
            >
              <div 
                style={{
                  padding: "0.25rem",
                  width: "1.5rem",
                  height: "1.5rem",
                  textAlign: "center",
                  color: "white"
                }}
              >
                  <AddCircleOutline />
              </div>
          </button>
          </div>):
          null
        }
      </div>
      <div
        style={{
          display: "block"
        }}
      >
        <ul
        style={{ margin: 0, padding: 0, userSelect: "none" }}
        data-testid="assetList"
        >
          {assetList}
        </ul>
      </div>
    </div>
  );
};

export default AssetList;
