import React, { FC } from "react";

export type LabelledInputProps = {
    label: string;
    input: React.ReactNode;
}

const LabelledInput: FC<LabelledInputProps> = (props: LabelledInputProps) => {

    const dataTestId = props.label.replace(' ', '-').toLocaleLowerCase();

    return (
        <label
            data-testid={dataTestId}
            style={{
                display: "block",
                padding: "0.5rem 0rem"
            }}
        >
            <div
                style={{ display: "block" }}
                data-testid={`${dataTestId}-label`}
            >
                {`${props.label}`}
            </div>
            <div 
                style={{ display: "block" }}
                data-testid={`${dataTestId}-input`}
            >
                {props.input}
            </div>
            
        </label>
    )
}

export default LabelledInput;