import { PayloadAction } from "@reduxjs/toolkit";
import {
  call,
  put,
  getContext,
  takeEvery,
  takeLatest,
  select
} from "redux-saga/effects";
import { IAssetsApiClient } from "../AssetsApiClient";
import { isEditModeSelector } from "./selectors";
import { Asset, assetSlice } from "./slices/assetSlice";
import { formSlice } from "./slices/formSlice";
import { organisationSlice, Organisation } from "./slices/organisationSlice";

export function* updateAssets(clearSelected: boolean = true, clearEditMode: boolean = true) {
  const apiClient = (yield getContext("apiClient")) as IAssetsApiClient;

  const assets = yield call(apiClient.retrieveAssets.bind(apiClient));
  yield put(assetSlice.actions.updateAssets({ assets, clearSelected, clearEditMode }));
}

export function* checkUserCanEdit() {
  const apiClient = (yield getContext("apiClient")) as IAssetsApiClient;
  const isAdmin = yield call(apiClient.isUserAdmin.bind(apiClient));
  yield put(formSlice.actions.setUserCanEdit(isAdmin));
}

export function* saveAsset(action: PayloadAction<Asset>) {
  const apiClient = (yield getContext("apiClient")) as IAssetsApiClient;
  if (action.payload.id && action.payload.id !== '') {
    // update existing asset
    yield call(apiClient.updateAsset.bind(apiClient), action.payload);
    yield updateAssets(false);
  }
  else if (!action.payload.id || action.payload.id === '') {
    // create new asset
    const newAssetId = (yield call(apiClient.addAsset.bind(apiClient), action.payload));
    yield updateAssets(true);
    yield put(assetSlice.actions.selectAsset(newAssetId));
  }
  else {
    yield(alert("Error: Attempted to save an asset with a blank id"));
    return
  }
  if(action.payload.id && action.payload.id !== '') {
    yield put(assetSlice.actions.selectAsset(action.payload.id));
  }
}

export function* deleteAsset(action: PayloadAction<string>) {
  const apiClient = (yield getContext("apiClient")) as IAssetsApiClient;
  yield call(apiClient.deleteAsset.bind(apiClient), action.payload);
  yield updateAssets(true);
}

export function* refresh() {
  const isEditMode = yield(select(isEditModeSelector));
  yield updateAssets(false, !isEditMode);
}

export function* updateOrganisations() {
  const apiClient = (yield getContext("apiClient")) as IAssetsApiClient;
  const organisations: Organisation[] = yield call(apiClient.retrieveOrganisations.bind(apiClient));
  yield put(organisationSlice.actions.updateOrganisations(organisations));
}

export function* rootSagas() {
  yield takeEvery(assetSlice.actions.selectAsset, checkUserCanEdit);
  yield takeEvery(assetSlice.actions.getAssetList, updateAssets);
  yield takeEvery(organisationSlice.actions.getOrganisationList, updateOrganisations);
  yield takeEvery(formSlice.actions.checkUserAccess, checkUserCanEdit);
  yield takeLatest(formSlice.actions.saveAsset, saveAsset);
  yield takeEvery(assetSlice.actions.deleteAsset, deleteAsset);
  yield takeEvery(assetSlice.actions.refresh, refresh);
}
